
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted,defineComponent } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { ElMessage } from "element-plus";
import { client } from "@/client";
import { Plus } from "@element-plus/icons-vue";
import {
  addLabelValue,
  getImgUrlFromStr,
  filterHtml,
  tree,
  loadFile,
} from "@/utils/tools";
import { ArticleStatus } from "@/shared/types/ArticleData";

export default defineComponent({
  name: "article-add",
  components: {
    Plus,Editor, Toolbar
  },
  setup() {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('<p>hello</p>')

    // 模拟 ajax 异步获取内容
    onMounted(() => {
        setTimeout(() => {
            valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
        }, 1500)
    })
    const toolbarConfig = {}
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF:{
        uploadImage:{
          // 自定义上传
          async customUpload(file: File, insertFn: (arg0: string, arg1: string, arg2: string) => void) {  // TS 语法
            let fileData = await loadFile(file);
            let ret = await client.callApi("pub/UploadImage",{file: fileData,filename: file.name});
            if (ret.isSucc) {
              let path = client.options.server + ret.res.path;
              insertFn(path,path, path)
            } else {
              ElMessage("上传文件必须小于1M");
            }
          // async customUpload(file, insertFn) {                   // JS 语法
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
          }
        }
      }
    }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor: any) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  data: () => {
    return {

      categorySelected: [] as any[], //-1默认选中顶级栏目
      categoryProps: { checkStrictly: true },

      activeName: "first", //tab 默认显示第一个
      activeIndex: 0, //tab 内容默认显示第一个
      category: [] as any[], //当前所有栏目
      cateList: [] as any[], //所有栏目
      autoImg: true,
      autoDes: true,
      picNum: 1,
      taglist: [] as any[],
      params: {
        //接口入参
        cid: 0,
        sub_cids: [] as number[],
        title: "",
        short_title: "",
        tagIds: [] as number[],
        attr: [] as string[],
        seo_title: "",
        seo_keywords: "",
        seo_description: "",
        source: "",
        author: "",
        description: "",
        img: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        content: "禅悦",
        status: "1",
        pv: 0,
        link: "",
      },

      field: [] as any [], //字段列表

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
      cur: 1,
      keywords: "",
    };
  },
  created() {
    this.query();
    this.searchTag();
  },
  methods: {
    async onUploadChange(res: { raw: File,name:string }){
      let file = res.raw;
      console.error(res)
      console.error(file)
      let isSuss = this.beforeUpload(file);
      if(!isSuss){
        return
      }
    let fileData = await loadFile(file);
    let ret = await client.callApi("pub/UploadImage", {
        file: fileData,
        filename: file.name
    });
    if (ret.isSucc) {
      let path = client.options.server + ret.res.path;
      this.params.img = path;
    } else {
          ElMessage({
            message:ret.err.message,
            type: "error",
          });
    }
    },
    beforeUpload(rawFile:File) {
      console.error(rawFile)
      let isJPG = rawFile.type === 'image/jpeg';
      let isPNG = rawFile.type === 'image/png';
      let isLt2M = rawFile.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
          ElMessage({
            message:"只能上传 JPG 或 PNG 格式的图片",
            type: "error",
          });
        return false;
      }
      if (!isLt2M) {
          ElMessage({
            message:"图片大小不能超过 2MB",
            type: "error",
          });
        return false;
      }
      return true;
    },
    handleClick(tab: { index: number; }) {
      this.activeIndex = tab.index;
    },

    setContent(article: string) {
      this.params.content = article;
    },

    //查询标签
    async searchTag(keywords?:string) {
      try {
        let res = await client.callApi("tag/List",{cur:this.cur,key: keywords});
        if (res.isSucc) {
          let arr: { label: any; value: any; }[] = [];
          res.res.list.forEach((item) => {
            arr.push({
              label: item.tag_name,
              value: item.id,
            });
          });

          this.taglist = arr;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //查询
    async query() {
      try {
        let res = await client.callApi("category/Find",{cur:this.cur,pageSize:1000});
        if (res.isSucc) {
          // let data = res.res.list;
          let dataTree = addLabelValue(tree(res.res.list));
          console.error(dataTree);
          let data = addLabelValue(res.res.list);
          this.cateList = data;
          this.category = [...dataTree];
        }
      } catch (error) {
        console.log(error);
      }
    },

    //选择栏目
    handleChange(e: string|any[]) {
      console.log(e);
      if (e[0] != -1) {
        this.params.cid = e[e.length - 1];
        this.findField(this.params.cid);
      } else {
        this.field = [];
      }
    },

    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },
    //上传缩略图
    upload(res: { code: number; data: { path: string; }; }) {
      if (res.code === 200) {
        this.params.img = res.data.path;
      }
    },

    //查询
    async findField(cid: number) {
      try {
        console.error(cid);
        // let res = await findField(cid);
        // if (res.code === 200) {
        //   this.field = res.data.fields;
        // }
      } catch (error) {
        console.log(error);
      }
    },
    //新增
    async create() {
      try {
        let params = { ...this.params };
        //判断是否有缩略图
        if (!params.img) {
          params.img = getImgUrlFromStr(params.content)[0];
        }
        //提取255字符作为文章描述
        if (!params.description) {
          params.description = filterHtml(params.content).substr(0, 255);
        }
        //处理模型字段信息
        // let fieldParams = {};
        // this.field.map((item) => {
        //   fieldParams[item.field_ename] = item.field_values;
        // });

        let res = await client.callApi("article/Add",{
          ...params,
          categoryId:params.cid,
          tagIds:params.tagIds,
          sub_cids:params.sub_cids.toString(),
          attr:params.attr.toString(),
          status:params.status as ArticleStatus
        })
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string|number) {
      let refs:any = this.$refs[formName];
      refs.validate((valid: any) => {
        if (valid) {
          this.create();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
